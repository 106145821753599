import React from "react";
import "./App.css";
import ScrapedData from "./ScrapedData/ScrapedData.tsx";

function App() {
  return (
    <div className="App">
      <ScrapedData />
    </div>
  );
}

export default App;
